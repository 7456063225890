.token-table {
    width: 50vw;
    max-height: 25vh;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    justify-self: center;
    font-family: 'Press Start 2P', sans-serif;
    overflow-y: scroll;
    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
  }
  
  .token-table th,
  .token-table td {
    padding: 8px;
    text-align: center;
    background-color: transparent;
    color: #fff; /* Set text color to white */
  }
  
  .token-table th {
    font-weight: bold;
  }
  
  .token-table tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    position: relative;
    font-size: smaller;
  }
  
  .token-table tr:before {
    content: "";
    position: absolute;
    top: 0;
    left: 75%;
    width: 1px;
    height: 100%;
    background-color: #ddd;
  }
  
  .token-table tr:nth-child(even) {
    background-color: transparent;
  }
  