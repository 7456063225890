@font-face {
  font-family: "Press Start 2P";
  src: url("./fonts/PressStart2P-Regular.ttf") format("truetype");
}

body {
  font-family: "Press Start 2P", sans-serif;
  background-color: black;
  margin: 0;
  padding: 0;
  overflow: none;
  animation: starAnimation 10s linear infinite;
  background-size: cover;
  cursor: help;
}

@keyframes starAnimation {
  0% {
    background: #000
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect x='0' y='0' width='200' height='200' fill='%23000000' /%3E%3Ccircle cx='100' cy='100' r='2' fill='%23ffffff' /%3E%3C/svg%3E")
      0 0/200px 200px;
  }
  100% {
    background: #000
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Crect x='0' y='0' width='200' height='200' fill='%23000000' /%3E%3Ccircle cx='100' cy='100' r='2' fill='%23ffffff' /%3E%3C/svg%3E") -200px -200px/200px
      200px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40vh;
  overflow: none;
}

.logo {
  height: 100px;
  margin: 16px;
  filter: invert(1);
}

.connect-button {
  display: flex;
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  height: fit-content;
  width: fit-content;
  font-size: 16px;
  border-radius: 4px;
  opacity: calc(0.05);
  filter: blur(100);
  transition: all ease-in-out 0.3s;
  z-index: 2;
  cursor: pointer;
}

:hover.connect-button {
  transform: scale(2);
  background-color: transparent;
  transition: all ease-in-out 0.8s;
  opacity: calc(0.6);
  filter: blur(0);
}

.switch-networks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.switch-button {
  align-self: center;
  cursor: pointer;
  display: flex;
  background-color: white;
  width: fit-content;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
  filter: blur(100);
  transition: all ease-in-out 0.3s;
  z-index: 2;
  font-size: xx-small;
  font-weight: 300;
  line-height: 20px;
  font-family: "Press Start 2P", sans-serif;
}

.connected-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: none;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.table-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 50vh;
}

.token-table {
  z-index: 2;
  display: flex;
  font-family: "Press Start 2P";
  src: url("./fonts/PressStart2P-Regular.ttf") format("truetype");
}

.profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: 4px;
  width: 200px;
  gap: 16px;
  padding: 16px;
  margin: 16px;
  border-radius: 4px;
  z-index: 2;
  max-height: 50vh;
}

.connected-image {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  object-fit: cover;
  cursor: pointer;
}

.profile {
  position: relative;
  background-color: transparent;
  color: white;
  border-radius: 4px;
  width: fit-content;
  height: auto;
  z-index: 0;
  text-align: left;
  align-items: center;
  padding: 16px 16px 16px 16px;
  font-size: small;
  font-weight: 300;
  line-height: 20px;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.profile-button {
  background-color: transparent;
  cursor: pointer;
  justify-content: center;
  border: none;
  width: 100%;
  padding-bottom: 8px;
  border-radius: 4px;
  transition: all ease-in-out 0.3s;
  z-index: 5;
}

.profile-button:hover {
  transform: scale(1.2);
  filter: blur(10px);
}

.tabs {
  z-index: 2;
}

.carousel-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-family: "Press Start 2P";
  z-index: 2;
  width: 35vw;
  margin-top: 60px;
}

.carousel-image {
  object-fit: cover;
  width: auto;
  max-height: 50vh;
  object-fit: contain;
  border-radius: 4px;
}

.refresh-button {
  align-self: center;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  width: fit-content;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 10px 20px;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
  filter: blur(100);
  transition: all ease-in-out 0.3s;
  z-index: 2;
  font-size: small;
  font-weight: 300;
  line-height: 20px;
  font-family: "Press Start 2P", sans-serif;
}

.refresh-button:hover{
  background-color: rgba(240, 248, 255, 0.346);
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  gap: 4px;
}

.tab-button {
  align-self: center;
  display: flex;
  background-color: transparent;
  width: fit-content;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 10px 20px;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
  filter: blur(100);
  transition: all ease-in-out 0.3s;
  z-index: 2;
  font-size: xx-small;
  cursor: pointer;
  font-weight: 300;
  line-height: 20px;
  font-family: "Press Start 2P", sans-serif;
}

.tab-button:hover{
  background-color: rgba(240, 248, 255, 0.346);
}
